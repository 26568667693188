@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,900;1,400;1,700&family=Playfair+Display:ital@1&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
}

body,
button {
  background: rgb(15, 15, 15);
  font-family: 'Lato', sans-serif;
}
@media print {
  body {
    background: white;
  }
}

/* bottom drawer */
#bottom-drawer.hidden {
  bottom: -266px;
}

div.MuiPaper-root.MuiPopover-paper {
  background: #1a1a1a;
  border:1px solid white;
  border-radius:0;
}

#mouse-over-popover div.MuiPaper-root.MuiPopover-paper {
  color: white;
  padding: 10px;
}
div.MuiPaper-root.MuiPopover-paper li.MuiMenuItem-root {
  color: white;
  font-family: 'Lato', sans-serif !important;
}
div.MuiPaper-root.MuiPopover-paper li.MuiMenuItem-root:hover {
  background: #2b2b2b;
}
div.MuiPaper-root.MuiPopover-paper li.MuiListSubheader-root {
  font-weight: 700;
  color: rgba(255, 255, 255, 0.7);
  font-variant: small-caps;
}
#variableMenu .MuiPaper-root ul {
  /* columns:2; */
  padding-bottom: 20px;
}
#variableMenu .MuiPaper-root ul li.Mui-disabled {
  break-after: avoid-column;
}

@media (max-width: 768px) {
  #variableMenu .MuiPaper-root ul {
    columns: 1;
  }
}

/* break-after: avoid; */
body {
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif !important;
  font-size: 13px;
  /* background-color: #111333; */
  overflow-x: hidden;
}

#mainContainer {
  position: absolute;
  top: 50px;
  width: 100%;
  height: calc(100% - 50px);
  overflow: hidden;
  z-index: 1;
}

#mainContainer.loading {
  opacity: 0.7;
}

#mainContainer.loading #variablePanel {
  pointer-events: none;
}

#root {
  height: calc(100% - 50px);
}

.navbar {
  display: flex;
  width: auto;
  height: 50px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.75px;
  font-weight: 400;
  font-stretch: normal;
  color: #0d0d0d;
}

@media print {
  #mainContainer {
    display: none;
  }
  .navbar {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .navbar ul {
    display: none !important;
  }
}

.navbar ul {
  list-style: none;
  display: flex;
  height: 100%;
  margin: 0px;
}

.navbar a {
  color: #ddd;
  text-align: center;
}

.nav-logo {
  color: white;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 20px;
}

.navbar li {
  display: flex;
  align-items: center;
}

.navbar li:hover {
  background: #444;
  color: #eee;
}

.navbar a {
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 7px 7px 0 0;
}
.mapboxgl-ctrl-top-right {
  position: fixed !important;
  max-height: 43px !important;
  overflow: hidden !important;
}

#view-MapView div,
.mapboxgl-control-container {
  z-index: 5 !important;
}
/* svg styles */

.cls-1 {
  stroke-miterlimit: 10;
  stroke-width: 4px;
}

/* mapbox fix */

button.mapboxgl-ctrl-attrib-button {
  display: none;
}

.highlighted {
  animation: highlightedShadow 1.5s linear infinite;
  transition: 250ms all;
  /* box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important; */
}

@keyframes highlightedShadow {
  0% {
    box-shadow: inset 0px 0px 0.5vw #ffce00;
  }
  25% {
    box-shadow: inset 0px 0px 0.5vw #ffce0055;
  }
  50% {
    box-shadow: inset 0px 0px 0.5vw #ffce00;
  }
  75% {
    box-shadow: inset 0px 0px 0.5vw #ffce0055;
  }
  100% {
    box-shadow: inset 0px 0px 0.5vw #ffce00;
  }
}

/* @keyframes highlightedShadow {
  0% {box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important;}
  25% {box-shadow:5px -5px 3px red, -5px 5px 3px red, 5px 5px 3px red, -5px -5px 3px red !important;}
  50% {box-shadow:-5px -5px 3px red, 0px 0px 3px red, 0px -5px 3px red, 0px 5px 3px red !important;}
  75% {box-shadow:5px 0px 3px red, -5px -5px 3px red, 5px 5px 3px red, -5px 0px 3px red !important;}
  100% {box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important;}
}

@-webkit-keyframes highlightedShadow {
  0% {box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important;}
  25% {box-shadow:5px -5px 3px red, -5px 5px 3px red, 5px 5px 3px red, -5px -5px 3px red !important;}
  50% {box-shadow:-5px -5px 3px red, 0px 0px 3px red, 0px -5px 3px red, 0px 5px 3px red !important;}
  75% {box-shadow:5px 0px 3px red, -5px -5px 3px red, 5px 5px 3px red, -5px 0px 3px red !important;}
  100% {box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important;}
} */

#loadingIcon {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  z-index: 50;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  pointer-events: none;
  mix-blend-mode: lighten;
}
#loadingIcon img {
  width: 100%;
  height: 100%;
}

#loadingIcon:after {
  content: 'Loading Data...';
  color: black;
  font-weight: bold;
  position: absolute;
  bottom: -30px;
  text-align: center;
  width: 100%;
  transform: translateX(-100%);
  background: white;
}

/* clear cache button */

#new-content-button {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: white;
  text-align: left;
  font-size: 1rem;
  opacity: 1;
  outline: none;
  border: none;
  padding: 5px;
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #f5f5f5;
  visibility: visible;
  transition: visibility 0s, opacity 0.5s linear;
  cursor: pointer;
  z-index: 500;
}

#new-content-button.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

#new-content-button span {
  font-weight: bold;
  display: block;
}

/* Mapbox */

.mapboxgl-ctrl-bottom-right * {
  display:none;
}